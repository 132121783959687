import React, { Component } from 'react'
import { navigate, graphql } from 'gatsby'
import get from 'lodash/get'
import PageLayout from '../components/layouts/PageLayout'
import lambdaAPI from '../lambdaAPI'
import { SearchHelper } from '../helpers'
import EventTracker from './../helpers/EventTracker'
import {SECTIONS} from './../utils/url'
import './../assets/styles/app.scss'

export default class PaymentProcessing extends Component {
    constructor(props) {
        super(props)

        const queryParams = SearchHelper.queryParamsToObject(this.props.location.search)
        this.paymentId = queryParams.id
    }

    componentDidMount() {
        // This should not happen but it does when doing npm run build
        if(!this.paymentId) {
            return
        }
        
        this.getStatus.call(this, this.paymentId)
    }

    getStatus = (paymentId) => {
        lambdaAPI.getStatus(paymentId)
        .then(res => {
            if(res.result.status !== 'complete' && res.result.errorCode == null) {
                setTimeout(() => this.getStatus(paymentId), 1000)
                return;
            }

            if(res.result.errorCode != null) {
                throw res.result.errorMessage
            }

            EventTracker.ready().then(() => {
                EventTracker.track({
                    eventType: res.frequency === 'single' ? EventTracker.EVENT_TYPES.ONE_OFF_DONATION : EventTracker.EVENT_TYPES.RECURRING_DONATION,
                    amount: res.amount,
                    frequency: res.frequency,
                })
                navigate(`${SECTIONS.SUPPORT_STARSHIP.url}/thank-you`, { replace: true })
            })
        })
        .catch(error => {
            console.log(error)
            navigate(`${SECTIONS.SUPPORT_STARSHIP.url}/payment-failed`, { replace: true, state: {message: error} })
        })
    }

    render() {
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemap.edges', [])
        sitemap = sitemap.map(link => link && link.node)
        return (
            <PageLayout className={`app-root processing-payment`} sitemap={sitemap}>
                <main className={`wrapper full-page`}>
                    <h2>Processing Payment</h2>
                    <p>We are processing your request. Please wait. Don't close the browser or refresh the page.</p>
                </main>
            </PageLayout>
        )
    }
}

export const pageQuery = graphql`
query getSiteMapDonationProcessing {
  allContentfulWidgetSitemap {
      edges {
          node {
              contentful_id
              userSection
              title
              slug
              links {
                  __typename
                  ... on ContentfulPageCustom {
                      slug
                      title
                      contentful_id
                      userSection
                  }
                  ... on ContentfulPageDirectoryOfServices {
                      id
                      title
                  }
                  ... on ContentfulPageDonation {
                      slug
                      title
                      contentful_id
                  }
                  ... on ContentfulPageGeneric {
                      slug
                      title
                      userSection
                  }
                  ... on ContentfulPageGroup {
                      slug
                      title
                      userSection
                  }
                  ... on ContentfulPageGuideline {
                      title
                      slug
                  }
                  ... on ContentfulPageWidgets {
                      title
                      slug
                      userSection
                  }
              }
          }
      }
  }
}`